require('plyr/dist/plyr.css');
require('./video.css');
import Plyr from 'plyr';

//const player = new Plyr('.video-player');

$(function() {
    $('.video-player').each(function(t){
        t=$(this);
        const player = new Plyr(t);
    });
});